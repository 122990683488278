import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  createStyles,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import { distance } from 'utils/geo'
import { GPSLocation } from 'model/GPSLocation'
import { Crag } from 'model/Crag'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    crags: {
      width: '100%',
      maxWidth: 780,
      backgroundColor: theme.palette.background.paper,
      margin: 'auto',
    },
    selectedItem: {
      background: '#ddd',
    },
    list: {
      background: '#f5f5f5',
    },
    copied: {
      color: '#333',
    },
  }),
)

interface CragDescriptionProps {
  crag: Crag
  selected: boolean
}

const CragDescription = ({ crag, selected }: CragDescriptionProps) => {
  const classes = useStyles()

  const [locationCopied, setLocationCopied] = useState<boolean>(false)

  useEffect(() => {
    setLocationCopied(false)
  }, [selected])

  return (
    <>
      <div>
        {crag.region.name}, {crag.country.name}
      </div>

      {selected && crag.parking_location && (
        <div>
          <strong>Parking</strong>: navigate with{' '}
          <a
            href={`https://waze.com/ul?ll=${crag.parking_location.lat},${crag.parking_location.lng}&navigate=yes`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Waze
          </a>
          <div>
            <small
              onClick={() =>
                navigator.clipboard
                  .writeText(`${crag.parking_location?.lat}, ${crag.parking_location?.lng}`)
                  .then(() => setLocationCopied(true))
              }
            >
              {crag.parking_location.lat}, {crag.parking_location.lng}
              {locationCopied && <span className={classes.copied}> - copied</span>}
            </small>
          </div>
        </div>
      )}
    </>
  )
}

interface Props {
  crags: Crag[] | undefined
  selectedCrag?: Crag
  setSelectedCrag: (crag: Crag) => void
  userLocation?: GPSLocation
}

export const CragList = ({ crags, selectedCrag, setSelectedCrag, userLocation }: Props) => {
  const classes = useStyles()

  return (
    <section className={classes.crags}>
      <List component="nav" aria-label="secondary" className={classes.list}>
        {crags ? (
          crags.map(crag => (
            <ListItem
              key={crag.id}
              button
              component="a"
              href={`#${crag.id}`}
              className={crag.id === selectedCrag?.id ? classes.selectedItem : undefined}
              onClick={() => setSelectedCrag(crag)}
            >
              <ListItemAvatar>
                <Avatar alt={crag.name} src={`/images/crags/${crag.id}.jpg`} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    {crag.name}
                    {userLocation && (
                      <small style={{ color: '#777' }}>
                        {' '}
                        ({distance(userLocation.lat, userLocation.lng, crag.location.lat, crag.location.lng).toFixed(
                          1,
                        )}{' '}
                        km)
                      </small>
                    )}
                  </>
                }
                secondary={<CragDescription crag={crag} selected={crag.id === selectedCrag?.id} />}
              />
            </ListItem>
          ))
        ) : (
          <ListItem>Loading...</ListItem>
        )}
      </List>
    </section>
  )
}
