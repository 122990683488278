import './App.css'
import React, { useEffect, useState, useCallback, Suspense } from 'react'
import { Grid } from '@material-ui/core'
import { Layout } from 'components/ui/Layout'
import { Crag } from 'model/Crag'
import { getCragList } from 'service/Api'
import { MapAnnotation } from 'model/MapAnnotation'
import { GPSLocation } from 'model/GPSLocation'
import { CragList } from 'components/ui/CragList'

const cragsToAnnotations = (crags?: Crag[], selectedCrag?: Crag): MapAnnotation[] | undefined =>
  crags?.map(crag => ({
    latitude: +crag.location.lat,
    longitude: +crag.location.lng,
    title: crag.name,
    subtitle: 'crag',
    selected: crag.id === selectedCrag?.id,
  }))

const MapViewLazy = React.lazy(() => import('components/ui/Map'))

export const App = () => {
  const [crags, setCrags] = useState<Crag[] | undefined>(undefined)
  const [selectedCrag, setSelectedCrag] = useState<Crag | undefined>(undefined)
  const [isMobile, setIsMobile] = useState<boolean>(true)
  const [userLocation, setUserLocation] = useState<GPSLocation | undefined>(undefined)

  const onResize = useCallback(() => {
    let mobileScreen = window.innerWidth <= 735
    if (mobileScreen !== isMobile) {
      setIsMobile(mobileScreen)
    }
  }, [isMobile])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  useEffect(() => {
    getCragList().then(crags => setCrags(crags))
  }, [])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        console.log('success', position)
        setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
      },
      error => {
        console.log('Error getting user position', error)
      },
    )
  }, [])

  const mapAnnotations = cragsToAnnotations(crags, selectedCrag)

  return (
    <Layout>
      <Grid container direction="row" className="App-container">
        <Grid item className="App-left-pane" style={isMobile ? { width: '100%' } : undefined}>
          <CragList
            crags={crags}
            selectedCrag={selectedCrag}
            setSelectedCrag={setSelectedCrag}
            userLocation={userLocation}
          />
        </Grid>
        {!isMobile && (
          <Grid item xs={12} sm className="App-right-pane">
            <Suspense fallback={<div>Loading...</div>}>
              <MapViewLazy
                annotations={mapAnnotations}
                activeAnnotation={mapAnnotations?.find(a => a.selected === true)}
              />
            </Suspense>
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}
