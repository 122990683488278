import React from 'react'

import { makeStyles, Theme, createStyles, AppBar, Toolbar, Typography /*, IconButton, Button */} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbar: {
      background: '#333',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
)

interface Props {}

export const Header = (props: Props) => {
  const classes = useStyles()

  return (
    <AppBar position="static" className={classes.navbar}>
      <Toolbar>
        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          ☰
        </IconButton> */}
        <Typography variant="h6" className={classes.title}>
          Crag Map
        </Typography>
        {/* <Button color="inherit">Log in</Button> */}
      </Toolbar>
    </AppBar>
  )
}
