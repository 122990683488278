import { Crag } from "model/Crag"

const baseUrl = 'https://api.cragmap.com/v1'

const getJson = (url: string) =>
  fetch(baseUrl + url)
    .then(response => response.json())
    .catch(error => {
      console.log('Looks like there was a problem: \n', error)
    })

export const getCragList = (): Promise<Crag[]> =>
  getJson('/items/crags?meta=total_count%2Cresult_count&limit=200&offset=0&sort=name&fields=*.*').then(response => response?.data)
