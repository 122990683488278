import React from 'react'
import { Header } from './Header'

interface Props {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => (
  <>
    <Header />
    <div style={{ height: 'calc(100% - 64px)' }}>{children}</div>
  </>
)
